.description_td {
    text-align: center;
    padding: 3px;
}

.description_th {
    font-weight: bold;
    text-align: center;
    padding: 3px;
}

.description_table {
    width: 100%;
    padding: 5px;
    border-radius: 5px;
    border: 1px solid #7c7c7c;
}

.description_code_table {
    background-color: #e6e6e6;
    color:black;
    padding: 5px;
    border-radius: 5px;
    border: 1px solid #e6e6e6;
}

.description_header {
    padding: 0px;
    margin: 0px;
}

.headerDiv {
    padding-top: 10px;
    padding-right: 15px;
    padding-left: 15px;
}

body {
    height:100vh;
}

#root {
    height: 100%;
}